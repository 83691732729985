import React, { useState, useRef, useEffect } from "react"
import { useSwipeable } from "react-swipeable"

import Slide from "./Slide"
import SwipeAlert from "./SwipeAlert"
import "./styles.scss"

const Slider = ({ location, slides }) => {
  const [current, setCurrent] = useState(0)

  const handlePreviousClick = () => {
    const previous = current - 1

    setCurrent(previous < 0 ? slides.length - 1 : previous)
  }

  const handleNextClick = () => {
    const next = current + 1

    setCurrent(next === slides.length ? 0 : next)
  }

  const handleSlideClick = index => {
    if (current !== index) {
      setCurrent(index)
    }
  }
  const wrapperTransform = {
    transform: `translateX(-${current * (100 / slides.length)}%)`,
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleNextClick()
    },
    onSwipedRight: () => {
      handlePreviousClick()
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <div {...handlers} className="slider">
      <SwipeAlert />
      <ul className="slider__wrapper" style={wrapperTransform}>
        {slides.map((slide, index) => (
          <Slide
            location={location}
            key={slide.node.prismicId}
            slide={slide}
            index={index}
            current={current}
            handleSlideClick={handleSlideClick}
          />
        ))}
      </ul>
      <div onClick={handleNextClick} className="slider-btn-next">
        →
      </div>
      <div onClick={handlePreviousClick} className="slider-btn-prev">
        ←
      </div>
    </div>
  )
}

export default Slider
