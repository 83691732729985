import React from "react"
import { motion } from "framer-motion"

import hand from "./handcursor.png"
import "./styles.scss"

const SwipeAlert = () => {
  const swipeAlert = {
    in: {
      y: [100, 0, 0, 100],
      opacity: [0, 1, 1, 0],
      visibility: "visible",
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 5,
        times: [0, 0.1, 0.9, 1],
        delay: 1.5,
      },
    },
    out: {
      y: 100,
      visibility: "hiddent",
      opacity: [1, 0],
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.3,
      },
    },
  }

  return (
    <motion.div variants={swipeAlert} animate={"in"} className="mobile-swipe">
      <div className="mobile-swipe-box">
        <span>← </span>
        <img src={hand} alt="" />
        <span> →</span>
      </div>
    </motion.div>
  )
}

export default SwipeAlert
