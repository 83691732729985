import React from "react"

import Slider from "./Slider"
import "./styles.scss"

const Paper = ({ slides, location }) => {
  return (
    <section className="paper">
      <Slider location={location} slides={slides} />
    </section>
  )
}

export default Paper
